import React from 'react';
import { Link } from 'react-router-dom';
import LoginPage from '../modules/auth/pages/login/LoginPage';
import ForgotPassword from '../modules/auth/pages/forgot-password/ForgotPassword';

const overrides = {
  login: {
    page: {
      logo: () => (
        <img
          className="logo"
          src={require('../../assets/img/logo_large.png').default}
          alt="logo"
          style={{ maxWidth: 150 }}
        />
      ),
      text: () => (
        <h1>
          {' '}
          Welcome to DND Finance card{' '}
          <span style={{ display: 'block', textAlign: 'center' }}>
            Administration
          </span>
        </h1>
      ),
    },
  },
  'login-override-page': () => <LoginPage />,
  'forgot-password-override-page': () => <ForgotPassword />,
  sidemenu: {
    logo: () => (
      <div className="dash-logo">
        <img
          src={require('../../assets/img/logo_large.png').default}
          alt="logo"
        />
      </div>
    ),
  },
  'get-user-info': 'auth/user/me',
  'breadcrumb-home-item': () => (
    <Link key="linkHome" to="/" className="breadcrumb-item">
      Users
    </Link>
  ),
  'no-config-model': () => null,
  'no-account-topbar': () => null,
};

export default overrides;
