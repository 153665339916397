import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import AppTable from '@launchpad/components/crud/AppTable';
import { currency } from '@launchpad/util/NumberHelper';
import { date } from '@launchpad/util/DateHelper';
import BreadcrumbHelper from '@launchpad/util/BreadcrumbHelper';
import TransactionModel from './models/TransactionModel';

const Transactions = (props) => {
  const [filters] = useState([
    {
      name: 'term',
      type: 'term',
    },
  ]);

  const [listParams, setListParams] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);

  const updateParams = (params) => {
    setListParams(params);
  };

  BreadcrumbHelper.setCrumbs([
    {
      label: 'Transactions',
    },
  ]);

  async function _downloadFile() {
    try {
      setIsDownloading(true);
      const result = await new TransactionModel().fetch({
        ...listParams,
        page: 1,
        limit: 5000,
        csvDownload: true,
      });
      const linkUrl = result.data[0].file;
      const link = document.createElement('a');
      link.href = linkUrl;
      document.body.appendChild(link);
      link.click();
      link.remove();
      setIsDownloading(false);
    } catch (e) {
      console.error(e);
      setIsDownloading(false);
    }
  }

  return (
    <div className="dashboard-management">
      <div className="d-flex justify-content-between align-items-center">
        <h1>
          <i className="fa fa-exchange" aria-hidden="true" /> Transactions
        </h1>
        <button
          type="button"
          className="button button-info"
          onClick={_downloadFile}
          disabled={isDownloading}
        >
          <i className="fa fa-fw fa-download">&nbsp;</i>Export as CSV
        </button>
      </div>
      <AppTable
        icon="exchange"
        title="Transactions"
        params={{
          user: props.user ? props.user.id : undefined,
        }}
        filter={filters}
        updateParams={updateParams}
        order={[
          {
            field: 'id',
            direction: 'desc',
          },
        ]}
        renderCell={(column, item, index) => {
          if (column.name === 'cardPan') {
            if (!item.cardPan) return '- demo -';
            return (
              <Link
                className="userCardLink"
                to={`/cards/details/${item.cardId}`}
              >
                {item.cardPan}
              </Link>
            );
          }
          if (column.name === 'amount') {
            return currency(item.amount, item.amountCurrency);
          }
          if (column.name === 'postDate') {
            return date(item.postDate, 'D / MMMM / Y');
          }
          if (column.name === 'cardPan') {
            return (
              <Link className="panLink" to={`/cards/details/${item.cardId}`}>
                {item.cardPan}
              </Link>
            );
          }
          if (column.name === 'note') {
            if (!item.note) return '- pending -';
          }
        }}
        getRowClass={(item, index) => {
          if (!item) return null;
          switch (true) {
            case item.status === 'declined':
              return 'spend';
            default:
              return 'load';
          }
        }}
        model={TransactionModel}
        getColumnActions={(member, index) => {
          return (
            <div>
              <Link
                to={`/transaction/details/${member.id}`}
                className="button-info"
              >
                Details
              </Link>
            </div>
          );
        }}
      />
    </div>
  );
};

export default Transactions;
